import promiseUntil from "./utils/promiseUntil";

export default function ShimmerAnim(el) {
  const paths = el.querySelectorAll("path");
  const noOfPaths = paths.length;

  // animation is now complete and can start picking random paths to glow
  setInterval(async () => {
    const selectionNo = Math.floor(Math.random() * Math.floor(noOfPaths));
    paths[selectionNo].classList.add("fade");
    await promiseUntil(2000);
    paths[selectionNo].classList.remove("fade");
  }, 600);

  paths.forEach((path) => {
    path.addEventListener("mouseover", async () => {
      console.log("mouseover");
      path.classList.add("fade");
      await promiseUntil(2000);
      path.classList.remove("fade");
    });
  });
}
