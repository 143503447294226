import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import h from "hyperscript";

function mobileBackup(el, device) {
  let html = "";
  if (device == "wiresafe") {
    html = h(
      "section.p-5.mt-20",
      // start of card
      h(
        "div.mb-20.text-center",
        h("img.rounded-md.mb-8.lazyload.shadow-md.w-full", {
          "data-src": `${siteUrl}video/${device}/output_0000.webp`,
        }),
        h("h3", "Venner WireSafe™"),
        h(
          "p",
          "Venner WireSafe™ is an innovative packaging product which can be added to current procedural packs, designed using human factors principles, as an engineered solution to prevent guidewire retention and facilitate a safe and effective procedure."
        )
      ),
      // card
      h(
        "div.mb-20.text-center",
        h("img.rounded-md.mb-8.lazyload.shadow-md.w-full", {
          "data-src": `${siteUrl}video/${device}/output_0269.webp`,
        }),
        h("h3", "WireSafe™ mini"),
        h(
          "p",
          "The Guidewire becomes the key to accessing items needed to complete the procedure"
        )
      ),

      h(
        "div.mb-20.text-center",
        h("img.rounded-md.mb-8.lazyload.shadow-md.w-full", {
          "data-src": `${siteUrl}video/${device}/output_0726.webp`,
        }),
        h("h3", "WireSafe™"),
        h(
          "p",
          "The complete safety solution securing all the items needed from the point of insertion to completion"
        )
      )

      // card
    );

    el.outerHTML = html?.outerHTML ?? "";
  } else {
    // <img class='rounded-md mb-8 lazyload shadow-md w-full' data-src="{{ image.first().url({ width: 339, height: 440 }) }}" alt="{{ heading }}">
    html = h(
      "section.p-5.mt-20",
      // start of card
      h(
        "div.mb-20.text-center",
        h("img.rounded-md.mb-8.lazyload.shadow-md.w-full", {
          "data-src": `${siteUrl}video/${device}/output_0000.webp`,
        }),
        h("h3", "The APA™ Video Laryngoscope"),
        h(
          "p",
          "The APA™ is an all-in-one traditional and Video Laryngoscope for Routine, Pediatric, Rescue and Difficult Airways."
        )
      ),
      // card
      h(
        "div.mb-20.text-center",
        h("img.rounded-md.mb-8.lazyload.shadow-md.w-full", {
          "data-src": `${siteUrl}video/${device}/output_0198.webp`,
        }),
        h("h3", "Handle Cover"),
        h(
          "p",
          "Made from medical grade non-slip polymer, removed by simply pulling down."
        )
      ),
      // card
      h(
        "div.mb-20.text-center",
        h("img.rounded-md.mb-8.lazyload.shadow-md.w-full", {
          "data-src": `${siteUrl}video/${device}/output_0511.webp`,
        }),
        h("h3", "AA Battery"),
        h(
          "p",
          "Non-proprietary AA Battery fits in the handle to supply light when the APATM is used a conventional Laryngoscope."
        )
      ),
      // card
      h(
        "div.mb-20.text-center",
        h("img.rounded-md.mb-8.lazyload.shadow-md.w-full", {
          "data-src": `${siteUrl}video/${device}/output_0594.webp`,
        }),
        h("h3", "LED Indicators"),
        h(
          "p",
          "Provide the user with a visual indication of remaining charge of the rechargeable battery."
        ),
        h("h3", "Buttons"),
        h("p", "+ & - Buttons may be used for white balance")
      ),
      h(
        "div.mb-20.text-center",
        h("img.rounded-md.mb-8.lazyload.shadow-md.w-full", {
          "data-src": `${siteUrl}video/${device}/output_0646.webp`,
        }),
        h("h3", "Connectivity"),
        h(
          "p",
          "Housed inside the viewer is a 3.7V 1300mAh battery providing over 7 days of application from a single charge."
        ),
        h("h3", "Video out cable"),
        h(
          "p",
          "Video out cable is attached here, in order to share your view with a larger audience or record the intubation. Designed as a simple push-fit."
        )
      ),
      h(
        "div.mb-20.text-center",
        h("img.rounded-md.mb-8.lazyload.shadow-md.w-full", {
          "data-src": `${siteUrl}video/${device}/output_0720.webp`,
        }),
        h("h3", "Disposable Blade"),
        h(
          "p",
          "The camera modules Provide the optics for the APATM and when engaged this turns the device on activating the light & video viewer. 3 modules provide the ability to use 10 different blades."
        )
      )
    );
  }
  el.outerHTML = html?.outerHTML ?? "";
}

export default function Showcase(el) {
  const grid = el.querySelector(".showcase__grid");
  const progressEl = el.querySelector("progress");
  const frameImg = document.querySelector(".showcase__frame");
  const maxFrames = +el.dataset.maxFrames;
  const length = el.dataset?.length ?? "1500%";
  const device = el.dataset.device;
  const scroll = el.querySelector(".scroll-indicator");
  let notices = [];
  const frames = [];

  if (window.innerWidth < 800) {
    mobileBackup(el, device);
    return;
  }

  triggerAnim();

  frameImg.src = `${siteUrl}video/${device}/output_0000.webp`;

  function createNotice(notice, square = 1, start = 0, end = 1) {
    grid.children[square].appendChild(notice);

    notices.push({
      notice,
      update: (progress) => {
        if (progress >= start && progress <= end) {
          notice.style.opacity = 1;
          notice.classList.add("in");
          // gsap.to(notice, { opacity: 1, display: "block", duration: 0.5 });
        } else {
          // gsap.to(notice, { opacity: 0, display: "none", duration: 0.5 });
          notice.style.opacity = 0;
          notice.classList.remove("in");
        }
      },
    });
  }

  function updateNotices(progress) {
    notices.forEach((notice) => notice.update(progress));
  }

  if (device === "wiresafe") {
    let notice = h(
      "div.text-white.pr-10",
      h("h3.text-left.text-anim", h("span", "Venner WireSafe™")),
      h(
        "p.text-left.text-anim",
        h(
          "span",
          "Venner WireSafe™ is an innovative packaging product which can be added to current procedural packs, designed using human factors principles, as an engineered solution to prevent guidewire retention and facilitate a safe and effective procedure."
        )
      )
    );
    createNotice(notice, 3, 0, 10);
    notice = h(
      "div.text-white",
      h(
        "p.text-left.text-anim.text-lg",
        h(
          "span",
          "The Guidewire becomes the key to accessing items needed to complete the procedure"
        )
      )
    );
    createNotice(notice, 0, 11, 32);
    notice = h(
      "div.text-white",
      h("h3.text-right.text-anim", h("span", "WireSafe™ mini"))
    );
    createNotice(notice, 2, 11, 32);

    notice = h(
      "div.text-white",
      h("h3.text-right.text-anim", h("span", "WireSafe™")),
      h(
        "p.text-right.text-anim",
        h(
          "span",
          "The complete safety solution securing all the items needed from the point of insertion to completion"
        )
      )
    );
    createNotice(notice, 8, 43, 77);
    notice = h(
      "div",
      h("img.w-2/3.mx-auto", { src: siteUrl + "wire safe.svg" })
    );
    createNotice(notice, 2, 80, 99);
  }

  if (device == "scope") {
    let notice = h(
      "div.text-text",
      h("h2.text-left.text-anim", h("span", "The APA™ Video Laryngoscope")),
      h(
        "p.text-left.text-anim",
        h(
          "span",
          "The APA™ is an all-in-one traditional and Video Laryngoscope for Routine, Pediatric, Rescue and Difficult Airways."
        )
      )
    );
    createNotice(notice, 3, 0, 19);

    notice = h(
      "div.text-text",
      h("h3.text-right.text-anim", h("span", "Handle Cover")),
      h(
        "p.text-right.text-anim",
        h(
          "span",
          "Made from medical grade non-slip polymer, removed by simply pulling down."
        )
      )
    );
    createNotice(notice, 5, 14, 56);

    notice = h(
      "div.text-text",
      h("h3.text-left.text-anim", h("span", "AA Battery")),
      h(
        "p.text-left.text-anim",
        h(
          "span",
          "Non-proprietary AA Battery fits in the handle to supply light when the APATM is used a conventional Laryngoscope."
        )
      )
    );
    createNotice(notice, 5, 56, 66);

    notice = h(
      "div.text-text.text-white",
      h("h3.text-left.text-anim.text-green", h("span", "LED Indicators")),
      h(
        "p.text-left.text-anim.text-white",
        h(
          "span",
          "Provide the user with a visual indication of remaining charge of the rechargeable battery."
        )
      )
    );
    createNotice(notice, 3, 71, 75);

    notice = h(
      "div.text-text.text-white",
      h("h3.text-right.text-anim.text-green", h("span", "Buttons")),
      h(
        "p.text-right.text-anim.text-white",
        h("span", "+ & - Buttons may be used for white balance")
      )
    );
    createNotice(notice, 5, 71, 75);

    notice = h(
      "div.text-text.text-white",
      h("h3.text-left.text-anim.text-green", h("span", "Connectivity")),
      h(
        "p.text-left.text-anim.text-white",
        h(
          "span",
          "Housed inside the viewer is a 3.7V 1300mAh battery providing over 7 days of application from a single charge."
        )
      )
    );
    createNotice(notice, 3, 79, 84);

    notice = h(
      "div.text-text.text-white",
      h("h3.text-right.text-anim.text-green", h("span", "Video out cable")),
      h(
        "p.text-right.text-anim.text-white",
        h(
          "span",
          "Video out cable is attached here, in order to share your view with a larger audience or record the intubation. Designed as a simple push-fit."
        )
      )
    );
    createNotice(notice, 5, 79, 84);

    notice = h(
      "div.text-text.text-white",
      h("h3.text-right.text-anim.text-blue", h("span", "Disposable Blade")),
      h(
        "p.text-right.text-anim.text-white",
        h(
          "span",
          "The camera modules Provide the optics for the APATM and when engaged this turns the device on activating the light & video viewer. 3 modules provide the ability to use 10 different blades."
        )
      )
    );
    createNotice(notice, 5, 85, 94);
  }

  function getFrameUrl(frame) {
    const frameString = frame.toString().padStart(4, "0");
    return siteUrl + `video/${device}/output_${frameString}.webp`;
  }

  function triggerAnim() {
    ScrollTrigger.create({
      trigger: ".showcase",
      start: "top top",
      end: length,
      pin: ".showcase",
      scrub: 1,
      // markers: true,
      ease: "none",
      onUpdate: (self) => {
        const progress = gsap.utils.clamp(0, 1, self.progress);
        const percent = progress * 100;
        progressEl.value = percent;
        updateNotices(percent);

        console.log(percent);

        if (percent > 2) {
          gsap.to(scroll, { opacity: 0, duration: 0.5 });
        } else {
          gsap.to(scroll, { opacity: 1, duration: 0.5 });
        }

        // Alpine.data("showcase", () => ({
        //   progress: progress * 100,
        // }));

        const frame = Math.floor(progress * maxFrames);
        const frameUrl = getFrameUrl(frame);
        frameImg.src = frameUrl;
        console.log(frameUrl);
      },
    });
  }
}
