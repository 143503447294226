import Alpine from "alpinejs";
window.Alpine = Alpine;

window.formateCurrency = (val) => {
  return val.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

Alpine.start();

export default Alpine;
