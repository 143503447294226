// import Plyr from "plyr";

export default function Video(el) {
  if (typeof Plyr !== "function") return;

  const player = new Plyr(el, {});
  player.on("timeupdate", () => {
    if (player.currentTime >= player.duration - 1) {
      player.stop();
    }
  });
}
