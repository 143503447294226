import "core-js/stable";
import "regenerator-runtime/runtime";
// import Alpine from "alpinejs";
import "lazysizes";

import "./app.scss";

import Headroom from "headroom.js";
import BannerAnim from "./js/BannerAnim";
import Showcase from "./js/Showcase";
import ShimmerAnim from "./js/ShimmerAnim";
import Alpine from "./js/InitAlpine";
import Form from "./js/Form";
import Calculator from "./js/Calculator";
import Video from "./js/Video";

/* 
  To include modules, import and include them anywhere in your JS. For example, 
  uncommenting the following two lines will force the jquery chunk to be 
  generated. Normally, only code that is actually used will be output as modules.
*/

const header = document.querySelector("#header");

const headroom = new Headroom(header, {
  // offset: 400,
});
headroom.init();

// import $ from "jquery"
// console.log( $ );

// Only used in dev
if (module.hot) {
  module.hot.accept();
}

HTMLElement.prototype.qa = function (selector) {
  return this.querySelectorAll(selector);
};

HTMLElement.prototype.q = function (selector) {
  return this.querySelector(selector);
};

HTMLElement.prototype.on = function (event, call) {
  return this.addEventListener(event, call);
};

const animHeader = document.querySelector("#anim-header");
const animGrid = document.querySelector(".anim-grid");
console.log(animGrid);

if (animHeader) {
  BannerAnim();
} else if (animGrid) {
  console.log("called");
  ShimmerAnim(animGrid);
}

const showcase = document.querySelector(".showcase");
if (showcase) {
  Showcase(showcase);
}

Calculator(document.querySelector(".calculator"));

document.querySelectorAll("[data-form]").forEach((el) => Form(el));
document.querySelectorAll(".video-container").forEach((el) => Video(el));
