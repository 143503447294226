function currency(val) {
  if (val < 0) {
    val = 0;
  }
  return (+val).toLocaleString("en-US", {
    style: "currency",
    currency: "usd",
    minimumFractionDigits: 0,
  });
}

function getStdUnit(val) {
  if (val < 3001) {
    return 9.5;
  } else if (val > 3000 && val < 4001) {
    return 8.5;
  } else if (val > 4000 && val < 5001) {
    return 8;
  } else if (val > 5000 && val < 6001) {
    return 5.5;
  } else if (val > 6000 && val < 7001) {
    return 4;
  } else if (val > 7000 && val < 10001) {
    return 3.5;
  } else if (val > 10000) {
    return 3;
  }
}

function getDifUnit(val) {
  if (val < 300) {
    return 20;
  } else if (val > 300 && val < 401) {
    return 18;
  } else if (val > 400 && val < 501) {
    return 16;
  } else if (val > 500 && val < 601) {
    return 15;
  } else if (val > 600 && val < 701) {
    return 13;
  } else if (val > 700 && val < 801) {
    return 12;
  } else if (val > 800 && val < 901) {
    return 11;
  } else if (val > 900 && val < 1001) {
    return 10;
  } else if (val > 1000) {
    return 9.5;
  }
}

export default function Calculator(el) {
  if (!el) return;
  const data = {};
  el.qa("input").forEach((inp) => (data[inp.name] = +inp.value));

  el.on("input", (e) => {
    data[e.target.name] = +e.target.value;
    const stdSpend = data.stdIntubation * getStdUnit(data.stdIntubation);
    const difSpend = data.diffIntubation * getDifUnit(data.stdIntubation);
    const annualSpend = stdSpend + difSpend;

    const currentSpend = data.currentStdSpend + data.currentDiffSpend;
    let saving = 0;

    // currentSpend - annualSpend
    if (data.stdIntubation > 0 || data.diffIntubation > 0) {
      saving = currentSpend - annualSpend;
    }

    el.q("[data-saving]").innerText = currency(saving);
    el.q("[data-new-spend]").innerText = currency(annualSpend);
  });
}
